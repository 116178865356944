










































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import { getToken, setToken, removeToken } from '@/utils/cookies'

@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger
  }
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar
  }

  get device() {
    return AppModule.device.toString()
  }

  private avatar = localStorage.getItem('avatar')

  private toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  private async logout() {
    removeToken();
    this.$router.push(`/login?redirect=${this.$route.fullPath}`)
  }
}
