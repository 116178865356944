import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
  mode: 'history',  // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
      meta: { 
        title: '音乐人上传端',
        hidden: true
      }
    },
    {
      path: '/404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
      meta: { hidden: true }
    },
    {
      path: '/',
      // component: Layout,
      redirect: '/profile/index',
      // children: [
      //   {
      //     path: 'dashboard',
      //     component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
      //     meta: {
      //       title: '首页',
      //       icon: 'dashboard'
      //     }
      //   }
      // ]
    },
    {
      path: '/profile',
      component: Layout,
      meta: {
        title: '个人资料',
        icon: 'dashboard'
      },
      redirect: '/profile/index',
      children: [
        {
          path: 'index',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/profile/index.vue'),
          meta: {
            title: '个人资料',
            icon: 'user'
          }
        },
        {
          path: 'modify',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/profile/modify.vue'),
          meta: {
            title: '修改资料',
            icon: 'nested',
            hidden: true
          }
        },
        {
          path: 'chain',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/profile/chain.vue'),
          meta: {
            title: '存证',
            icon: 'nested',
            hidden: true
          }
        },
        {
          path: 'realname',
          component: () => import(/* webpackChunkName: "tree" */ '@/views/profile/realname.vue'),
          meta: {
            title: '实名认证',
            icon: 'nested',
            hidden: true
          }
        }
      ]
    },
    {
      path: '/music',
      redirect: '/music/index',
      component: Layout,
      meta: {
        title: '音乐管理',
        icon: 'form'
      },
      children: [
        {
          path: 'upload',
          component: () => import(/* webpackChunkName: "form" */ '@/views/music/upload.vue'),
          meta: {
            title: '音乐上传',
            icon: 'form',
            hidden: true
          }
        },
        {
          path: 'index',
          component: () => import(/* webpackChunkName: "form" */ '@/views/music/index.vue'),
          meta: {
            title: '作品列表',
            icon: 'table'
          }
        }
      ]
    },
    {
      path: '/protect',
      component: Layout,
      meta: {
        title: '原创音乐守护计划',
        icon: 'form'
      },
      children: [
        {
          path: 'index',
          component: () => import('@/views/protect.vue'),
          meta: {
            title: '原创音乐守护计划',
            icon: 'form'
          }
        }
      ]
    },
    {
      path: '/support',
      component: Layout,
      meta: {
        title: '原创音乐推广扶持计划',
        icon: 'form'
      },
      children: [
        {
          path: 'index',
          component: () => import('@/views/support.vue'),
          meta: {
            title: '原创音乐推广扶持计划',
            icon: 'example'
          }
        }
      ]
    },
    {
      path: '/qa',
      component: Layout,
      meta: {
        title: '其他',
        icon: 'form'
      },
      children: [
        {
          path: 'index',
          component: () => import('@/views/qa.vue'),
          meta: {
            title: '帮助中心',
            icon: 'nested'
          }
        }
      ]
    },
    // {
    //   path: '/nested',
    //   component: Layout,
    //   redirect: '/nested/menu1',
    //   meta: {
    //     title: 'Nested',
    //     icon: 'nested'
    //   },
    //   children: [
    //     {
    //       path: 'menu1',
    //       component: () => import(/* webpackChunkName: "menu1" */ '@/views/nested/menu1/index.vue'),
    //       redirect: '/nested/menu1/menu1-1',
    //       meta: { title: 'Menu1' },
    //       children: [
    //         {
    //           path: 'menu1-1',
    //           component: () => import(/* webpackChunkName: "menu1-1" */ '@/views/nested/menu1/menu1-1/index.vue'),
    //           meta: { title: 'Menu1-1' }
    //         },
    //         {
    //           path: 'menu1-2',
    //           component: () => import(/* webpackChunkName: "menu1-2" */ '@/views/nested/menu1/menu1-2/index.vue'),
    //           redirect: '/nested/menu1/menu1-2/menu1-2-1',
    //           meta: { title: 'Menu1-2' },
    //           children: [
    //             {
    //               path: 'menu1-2-1',
    //               component: () => import(/* webpackChunkName: "menu1-2-1" */ '@/views/nested/menu1/menu1-2/menu1-2-1/index.vue'),
    //               meta: { title: 'Menu1-2-1' }
    //             },
    //             {
    //               path: 'menu1-2-2',
    //               component: () => import(/* webpackChunkName: "menu1-2-2" */ '@/views/nested/menu1/menu1-2/menu1-2-2/index.vue'),
    //               meta: { title: 'Menu1-2-2' }
    //             }
    //           ]
    //         },
    //         {
    //           path: 'menu1-3',
    //           component: () => import(/* webpackChunkName: "menu1-3" */ '@/views/nested/menu1/menu1-3/index.vue'),
    //           meta: { title: 'Menu1-3' }
    //         }
    //       ]
    //     },
    //     {
    //       path: 'menu2',
    //       component: () => import(/* webpackChunkName: "menu2" */ '@/views/nested/menu2/index.vue'),
    //       meta: { title: 'Menu2' }
    //     }
    //   ]
    // },
    {
      path: '*',
      redirect: '/404',
      meta: { 
        title: '404',
        hidden: true
      }
    }
  ]
})
