import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import { getToken, setToken, removeToken } from '@/utils/cookies'


NProgress.configure({ showSpinner: false })

const whiteList = ['/login']

router.beforeEach(async(to: Route, _: Route, next: any) => {
  // 处理在登陆状态下，打开两个扫码页面，使用不同的微信分别扫码，结果第二次扫码登陆后，使用第一个微信的信息登陆问题修复
  if(to.path === '/login' && getToken() && (to.query.hasOwnProperty('code') && to.query.code)){
    removeToken();
    localStorage.removeItem('avatar')
  }
  NProgress.start()
  const hasToken = getToken()
  // Determine whether the user has logged in
  if (hasToken) {
    if (to.path === '/login') {
      // If is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      next();
      // Check whether the user has obtained his permission roles
      // if (UserModule.roles.length === 0) {
        // try {
        //   // Get user info, including roles
        //   // await UserModule.GetUserInfo()
        //   // // Set the replace: true, so the navigation will not leave a history record
        //   // next({ ...to, replace: true })
        // } catch (err) {
        //   // Remove token and redirect to login page
        //   UserModule.ResetToken()
        //   Message.error(err || 'Has Error')
        //   next(`/login?redirect=${to.path}`)
        //   NProgress.done()
        // }
      // } else {
      //   next()
      // }
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // In the free login whitelist, go directly
      next()
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  // set page title
  document.title = to.meta.title
})
